/* eslint-disable no-console,no-undef,camelcase */
import React, { Component, Suspense, lazy } from 'react';
import PropTypes from 'prop-types';
import { getStatus, getTimeToShow, getLastSyncDetails, convertDateInTimezone, commaSeperator, rankingDashboard, convertMilesToKm, convertMonthsWeeksText } from "../../../utils/methods";
import { connect } from "react-redux";
import Image from '../../Image';
import { joinOrLeaveChallenge, getInviteAmigosList } from "../../../redux/actions/challengeActions";
import { MyTaskContainerV2 } from '../MyTaskTab/styles';
import { EventsButton, ButtonContainer, CompletePointCard } from '../styles';
import { AboutContainer } from '../../EventDetails/styles';
import { PositionCard, LastSync, InformationCard } from '../OverViewScreen/styles';
import { Border } from '../../EventDetails/styles';
import moment from 'moment';
// import isEmpty from 'lodash/isEmpty';
// import { ImageUrl } from '../../../utils/constants';
import isNull from 'lodash/isNull';
import Waiting from '../../Waiting';
const InviteAmigoPopUp = lazy(() => import("../InviteAmigoPopUp/inviteChallengePopup"));
// import isUndefined from 'lodash/isUndefined';
import { withTranslation } from 'react-i18next';
import { ImageUrl } from '../../../utils/constants';
const LeaveChallengePopup = React.lazy(() => import('./leaveChallengePopup/index'));
// import LazyImage from '../../common/LazyImage/LazyImage';
import parse from 'react-html-parser';

class TeamChallengeRightSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      time: [],
      isChallengeLocked: getStatus(props.challenge),
      isUpdatedImage: false,
      printChallengeCSV: false,
      updatedChallengeCSV: {},
      showInviteAmigoPopup: false,
      showParticipants: false,
      leaveBtn:false, 
      leaveChallengePopUp:false,
      isButtonDisabled: false
    }
  }
  componentDidMount() {
    const { challenge } = this.props;
    // getUserOverviewDetails(challenge.id, challenge.event_type, this.props.userId);
    this.checkBase64(this.props.profileImage);
    this.interval = setInterval(() => {
      this.setState({
        time: getTimeToShow(challenge, 'challenge')
      })
    }, 1000);

  }

  joinChallenge = (challengeId, isJoined) => {
    const { joinOrLeaveChallenge } = this.props;
    this.setState({ isButtonDisabled: true });
    window.setTimeout(() => {
      this.setState({ isButtonDisabled: false });
    }, 2000);
    const data = {
      challenge_id: challengeId,
      is_joined: isJoined
    };
    joinOrLeaveChallenge(data);
  };
  checkBase64(profileImage) {
    if (profileImage) {
      let arr = profileImage.split('/');
      if (arr[0] !== 'profile-Image') {
        this.setState({
          isUpdatedImage: true
        })
      } else {
        this.setState({
          isUpdatedImage: false
        })
      }
    }
  }
  componentDidUpdate(prevProps) {
    if (prevProps.challenge !== this.props.challenge) {
      clearInterval(this.interval);
      this.interval = setInterval(() => {
        this.setState({
          time: getTimeToShow(this.props.challenge, 'challenge')
        })
      }, 1000);
    }
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.profileImage != nextProps.profileImage) {
      this.checkBase64(nextProps.profileImage)
    }
  }
  componentWillUnmount() {
    clearInterval(this.interval);
  }
  showProfileImage = () => {
    const { profileImage } = this.props;
    const { isUpdatedImage } = this.state;
    if (isUpdatedImage) {
      return (<img src={profileImage} alt="avatar" />)
    }
    else {
      return (<Image image={profileImage} alt="avatar" />)
    }

  };

  showHideInviteAmigoPopup = () => {
    this.setState((prevState) => ({
      showInviteAmigoPopup: !prevState.showInviteAmigoPopup
    }), () => this.props.getInviteAmigosList(this.props.userId, this.props.challenge.id))
  };

  hideLeaveModal = ()=>{
    this.setState({leaveChallengePopUp:false})
  }
  showLeaveModal = ()=>{
    this.setState({leaveChallengePopUp:true})
  }

  fallBack = (e) => {
    e.target.src = ImageUrl+'/images/PeoplePage/teams-logo.svg'
  }

  render() {
    const { challenge, syncTime, userId, t, user, teamChallenge } = this.props;
    const { time, isButtonDisabled } = this.state;
    if ((!(this.props.userChallengeDetails) || !userId)) {
      return <Waiting />
    }
    let challengeOver = true;
    // let challengeCompetitors = {};
    // let propsChallengeCompetitors = [];
    // if (challenge.challenge_type === 'new_group') {
    //   const { user_details: userDetails } = this.props.userChallengeDetails;
    //   propsChallengeCompetitors = userDetails;

    // } else {
    //   propsChallengeCompetitors = this.props.userChallengeDetails['user_details'];
    // }

    time.map((step) => {
      const values = step.split(' ');
      if (values && parseInt(values[0],10) > 0) {
        challengeOver = false;
      }
    });
    // const valid = convertDateInTimezone(challenge.end_date)._d >= moment()._d;
    // if (challenge.challenge_status !== 'over' || valid) {
    //   challengeCompetitors = propsChallengeCompetitors;
    // }
    // else if (this.props.userChallengeDetails) {
    //   challengeCompetitors = propsChallengeCompetitors;
    // }
    const syncUpdate = getLastSyncDetails(syncTime);
    const joinOrLeave = (challenge.id !== 53) ? !challengeOver && (challenge.challenge_status === 'joined' ? 0 : 1) : (challenge.challenge_status === 'joined' ? 0 : 1);
    // const ChallengeType = challenge.event_type.split(' ')[1];
    // if (isNull(challengeCompetitors)) {
    //   return <Waiting />
    // }

    const ChallengeTypes = challenge.event_type;
    const activityType = ChallengeTypes === 'Total Distance' ? 'distance' : ChallengeTypes === 'Total Steps' ? this.props.t('steps') : 'calories';
    let today = moment()._d;
    let startDay = convertDateInTimezone(challenge.start_date)._d;
    const DistanceUnit = localStorage.getItem('DISTANCE');

    const users = teamChallenge && teamChallenge.team_challenge_details && teamChallenge.team_challenge_details.team_details && teamChallenge.team_challenge_details.team_details.team_users && teamChallenge.team_challenge_details.team_details.team_users.length > 0 &&  teamChallenge.team_challenge_details.team_details.team_users || [];
    let loggedInUser;
    for (let i = 0; i < users.length; i++) {
      if(users[i].uid === user.uid){
        loggedInUser = users[i]
      }
    }
    let daysDifference = challenge && (new Date(challenge.end_date) -new Date(challenge.start_date))/(1000 * 60 * 60 * 24);
    return (
      <MyTaskContainerV2>
        {challenge.challenge_status == 'over' && teamChallenge && teamChallenge.team_challenge_details && teamChallenge.team_challenge_details.team_details?
          <CompletePointCard>
            <div className='width'>{"You recorded a whopping"}&nbsp;<div className='value'>{challenge.event_type === 'Total Steps' ?`${commaSeperator(loggedInUser.steps)} Steps`:challenge.event_type === 'Total Calories'?`${commaSeperator(loggedInUser.calories)} calories` :`${commaSeperator(loggedInUser.distance)} Miles`}</div></div>
            <div className='width'>{"in just"}&nbsp; <div className='value'>{`${Math.floor(daysDifference)} Days earning`}&nbsp;{challenge.challenge_point} Points!</div></div>
          </CompletePointCard>:null}
        {(challenge.challenge_status === 'joined'||challenge.challenge_status === 'over') && teamChallenge && teamChallenge.team_challenge_details && teamChallenge.team_challenge_details.team_details ?
          <React.Fragment>
            <div style={{background:'#005C87', padding:'15px', width:'100%', borderRadius:'6px 6px 0px 0px',display:'flex', justifyContent:'space-between',alignItems:'center'}}>
              <div>
                <span style={{color:'white',fontSize:'16px',lineHeight:'20px', fontFamily:"Rubik-Medium"}}>{this.props.t("Overall Team Position")}</span>
              </div>
              <div style={{color:'white', fontFamily:"Rubik-Medium"}}>
                {isNull(teamChallenge.team_challenge_details.team_details.team_position) ? <div>0</div> :
                  (today < startDay) ? <div>-</div> :
                    <div>{teamChallenge.team_challenge_details.team_details.team_position}{rankingDashboard(teamChallenge.team_challenge_details.team_details.team_position - 1)} {t("Rank")}</div>}
              </div>
            </div>
            <InformationCard>
              <div className='teamChallenge' style={{display:'flex',alignItems:'center',justifyContent:'space-between', width:'100%'}}>
                <div  style={{display:'flex',alignItems:'center', width:'100%'}}>
                  <div className="image">
                    <img onError={this.fallBack} src={`${ImageUrl +"/"+ teamChallenge.team_challenge_details.team_details?.logo}`}  alt="back-arrow" />
                  </div>
                  <div className="field">
                    {t("Team Name")}
                  </div>
                </div>
                <div style={{marginRight:'15px', width:'100%', display:'flex', justifyContent:'end'}}>
                  <span className='value'>{t(teamChallenge.team_challenge_details.team_details.name)}</span>
                </div>
              </div>
            </InformationCard></React.Fragment>:null}

        {(challenge.challenge_status === 'joined'||challenge.challenge_status === 'over') && teamChallenge && teamChallenge.team_challenge_details && teamChallenge.team_challenge_details.team_details ?
          <PositionCard color={challenge.event_type === 'Total Steps'?"#76AB78":challenge.event_type === 'Total Calories'?"#FFBF73":"#85C0EA"} valueColor={challenge.event_type === 'Total Steps'?"#76AB78":challenge.event_type === 'Total Calories'?"#FFBF73":"#85C0EA"}>
            <div style={{width:'100%'}}>
              <div>
                <div className="attendies">{t("Total Team Contributions ")}
                </div>
                <div>
                  <span className='value'>
                    <div style={{alignItems:'center', display:'flex'}}>
                      {isNull(teamChallenge.team_challenge_details.team_details.team_contribution) ? 0 :activityType === 'distance'?DistanceUnit === "KILOMETER"? `${commaSeperator(convertMilesToKm( teamChallenge.team_challenge_details.team_details.team_contribution))} ` : `${commaSeperator( teamChallenge.team_challenge_details.team_details.team_contribution)} ` :`${commaSeperator( teamChallenge.team_challenge_details.team_details.team_contribution)} `} <div className="type" style={{margin:'0px 10px', color:"#005C87", fontFamily:"rubik"}}>{` ${activityType === 'distance' ? DistanceUnit === "KILOMETER"? 'Km' :'Miles' : activityType}`}</div></div>
                    <div />
                  </span>
                </div>
              </div>
              <Border margin="auto 15px" background="#005C874D"/>
              <div style={{fontSize: '16px',fontFamily: 'rubik',lineHeight: '19px',marginLeft: '10px'}}>
                <span style={{color:"#005C87"}}>              {(today < startDay)?<span >-</span>:
                  <span className='typeValue'>{commaSeperator(Number(teamChallenge.team_challenge_details.team_details.team_difference_point))} {challenge.event_type === 'Total Steps' ? <span style={{fontFamily: 'rubik', color:"#005C87"}}>{t("Steps")}</span> : challenge.event_type === 'Total Calories' ? <span style={{fontFamily: 'rubik',color:"#005C87"}}>{t("Calories")}</span> : DistanceUnit === "KILOMETER"?<span style={{fontFamily: 'rubik', color:"#005C87"}}>{"Km"}</span>:<span style={{fontFamily: 'rubik',color:"#005C87"}}>{ t("Miles")}</span>}</span>} {t(teamChallenge.team_challenge_details.team_details.ahead_or_behind || "-" )} {teamChallenge.team_challenge_details.team_details.ahead_or_behind_team_name || "-"}</span>
              </div>
            </div>
          </PositionCard>:null
        }
        {challenge.challenge_status == 'joined' && challenge.challenge_status !== 'over' && !isNull(challenge.daily_goal) && challenge.daily_goal > 0 &&
              <InformationCard>
                <div className="image">
                  <img src="/public/images/CompanyDashBoardV2/dailyGoal.png" alt="back-arrow" />
                </div>
                <div className="card-wrapper">
                  <div className="first-card">
                    <div className="field">
                      {t("Team Daily Goal")}
                    </div>
                    <div className="value" style={{color:"#FFBF73"}}>
                      {challenge.event_type === 'Total Distance'?DistanceUnit === "KILOMETER"?commaSeperator(convertMilesToKm(challenge.daily_goal)) :commaSeperator(challenge.daily_goal) :commaSeperator(challenge.daily_goal)}&nbsp;
                    </div>
                  </div>
                  <Border margin="auto 15px auto 0px" size="1" background="#005C874D"/>
                  <div className="last-card">
                    <div className="field">
                      {t("My Total Target Goal")}
                    </div>
                    <div className="value" style={{color:"#FFBF73"}}>
                      {challenge.event_type === 'Total Distance'?DistanceUnit === "KILOMETER"?commaSeperator(convertMilesToKm(challenge.target_goal)) :commaSeperator(challenge.target_goal) :commaSeperator(challenge.target_goal)}&nbsp;
                    </div>
                  </div>
                </div>
              </InformationCard>
        }
        {/* {challenge.challenge_status === 'joined' && !isNull(challenge.daily_goal) && challenge.daily_goal > 0 &&
          <InformationCard>
            <div className="image">
              <img src="/public/images/CompanyDashBoardV2/dailyGoal.png" alt="back-arrow" />
            </div>
            <div className="card-wrapper">
              <div className="first-card">
                <div className="field">
                  {t("My Daily Goal")}
                </div>
                <div className="value">
                  {challenge.event_type === 'Total Distance'?DistanceUnit === "KILOMETER"?commaSeperator(convertMilesToKm(challenge.daily_goal)): commaSeperator(challenge.daily_goal): commaSeperator(challenge.daily_goal)}&nbsp;<div className="type">{challenge.event_type === 'Total Steps' ? t("Steps") : challenge.event_type === 'Total Calories' ? t("Calories") : DistanceUnit === "KILOMETER"?"Km": t("Miles")}</div>
                </div>
              </div>
              <Border margin="auto 15px auto 0px" size="1" />
              <div className="last-card">
                <div className="field">
                  {t("My Total Target Goal")}
                </div>
                <div className="value">
                  {challenge.event_type === 'Total Distance'?DistanceUnit === "KILOMETER"?commaSeperator(convertMilesToKm(challenge.target_goal)) :commaSeperator(challenge.target_goal): commaSeperator(challenge.target_goal)}&nbsp;<div className="type">{challenge.event_type === 'Total Steps' ? t("Steps") : challenge.event_type === 'Total Calories' ? t("Calories") : DistanceUnit === "KILOMETER"?"Km": t("Miles")}</div>
                </div>
              </div>
            </div>
          </InformationCard>
        } */}
        {(challenge.challenge_status === 'joined'||challenge.challenge_status === 'over') && (challenge.event_type === 'Total Steps' && teamChallenge && teamChallenge.team_challenge_details && teamChallenge.team_challenge_details.team_details  ?
          <PositionCard color={'#76AB78'}>
            <div>
              <div className='img'>
                <img src={ImageUrl+"/ChallengeDetailsScreen/Steps.svg"} alt="back-arrow" />
              </div>
            </div>
            <div>
              <div>
                <div className="attendies">{t("My Total Steps")}
                </div>
                {loggedInUser.steps !== null ? (today < startDay) ? <div >-</div> : <div >{commaSeperator(loggedInUser.steps)}
                </div> : (today < startDay) ? <div >-</div> : <div >0</div>}
              </div>
              <Border margin="auto 15px" background="#005C874D"/>
              <div>
                <div>{t("My Daily Average")}</div>
                {(today < startDay)?<div >-</div>:
                  <div >{commaSeperator(teamChallenge.team_challenge_details.team_details.daily_average)}</div>}
              </div>
            </div>
          </PositionCard> :
          challenge.event_type === 'Total Calories' && teamChallenge && teamChallenge.team_challenge_details && teamChallenge.team_challenge_details.team_details ?
            <PositionCard color={'#FFBF73'}>
              <div>
                <div className='img'>
                  <img src={ImageUrl+"/ChallengeDetailsScreen/Calories.svg"} alt="back-arrow" />
                </div>
              </div>
              <div>
                <div>
                  <div className="attendies">{t("My Total Calories")}
                  </div>
                  {loggedInUser.calories !== null ? (today < startDay) ? <div >-</div> : <div >{commaSeperator(loggedInUser.calories)}
                  </div> : (today < startDay) ? <div >-</div> : <div >0</div>}
                </div>
                <Border margin="auto 15px" background="#005C874D"/>
                <div>
                  <div>{t("My Daily Average")}</div>
                  {(today < startDay)?<div >-</div>:
                    <div >{commaSeperator(teamChallenge.team_challenge_details.team_details.daily_average)}</div>}
                </div>
              </div>
            </PositionCard> : teamChallenge && teamChallenge.team_challenge_details && teamChallenge.team_challenge_details.team_details ?
              <PositionCard color={'#85C0EA'}>
                <div>
                  <div className='img'>
                    <img src={ImageUrl + "/ChallengeDetailsScreen/Distance.svg"}/>
                  </div>
                </div>
                <div>
                  <div>
                    <div className="attendies">{t("My Total Distance")}
                    </div>
                    {loggedInUser.distance !== null ? (today < startDay) ? <div >-</div> : <div >{DistanceUnit === "KILOMETER"?commaSeperator(convertMilesToKm(challenge.distance)):commaSeperator(loggedInUser.distance)}
                    </div> : (today < startDay) ? <div >-</div> : <div >0</div>}
                  </div>
                  <Border margin="auto 15px" background="#005C874D"/>
                  <div>
                    <div>{t("My Daily Average")}</div>
                    {(today < startDay)?<div >-</div>:
                      <div >{DistanceUnit === "KILOMETER"?commaSeperator(convertMilesToKm(teamChallenge.team_challenge_details.team_details.daily_average)): commaSeperator(teamChallenge.team_challenge_details.team_details.daily_average)}</div>}
                  </div>
                </div>
              </PositionCard>:null)}
        {challenge.challenge_status === 'joined' && syncUpdate.length>0 &&<LastSync>
          <div>{t("Last Sync")}</div>
          {!(today < startDay)?<div>{syncUpdate.length >= 2 ? ( localStorage.getItem("lang") != "fr" ? `${syncUpdate[0]} ${syncUpdate[1]} ${this.props.t("ago")}` : `${this.props.t("ago")} ${convertMonthsWeeksText(syncUpdate[0], this.props.t)}, ${convertMonthsWeeksText(syncUpdate[1], this.props.t)} `) : ( localStorage.getItem("lang") != "fr" ? `${syncUpdate[0]} ${this.props.t("ago")}` : `${this.props.t("ago")} ${convertMonthsWeeksText(syncUpdate[0], this.props.t)}`)}</div>:<div>-</div>}
        </LastSync>}
        {/* {((role === "ADMIN" || role === "WELLNESS_ADMIN"|| challenge.created_by === userId) && challenge.is_user_challenge===1) && challenge.challenge_status !== 'over' && challenge.challenge_status === 'joined' && 
        <EventsButton backgroundColor={"rgb(253, 113, 117)"} color={"#fff"} padding={'13px 8px 13px 8px'} font={"18px"} onClick={() => this.showHideInviteAmigoPopup()}>
          {t("Invite Buddies")}
        </EventsButton>
        } */}

        
        {challenge.challenge_type == 'group' && challenge.challenge_status !== 'over' ?
          <ButtonContainer >
            {challenge.is_joined === 1 ?
              <React.Fragment>
                <AboutContainer marginTop={'0px 0px 15px 0px'} color={"#005C87"} colorValue="#005C87">
                  <div>{t("About The Challenge")}</div>
                  <div >{challenge.body}</div>
                </AboutContainer>{/*  */} 
                {<EventsButton  backgroundColor={"#005C870D"} color={"#005C87"} padding={'15px 8px 15px 8px'} font={"18px"} margin={"25px"}>
                  {t("You Joined This Challenge")}
                </EventsButton> }

                {/* {this.state.leaveBtn && <EventsButton  onClick={()=>{this.setState({leaveChallengePopUp:true})}} backgroundColor={"#FD7175"} color={"white"} padding={'15px 8px 15px 8px'} font={"18px"} margin={"25px"}>
                  {t("Leave Challenge")}
                </EventsButton> } */}
              </React.Fragment> :
              <EventsButton backgroundColor={"#005C87"} color={"white"} boxShadow="0px 8px 24px 0px #005C8780" onClick={isButtonDisabled?null:() => this.joinChallenge(challenge.id, joinOrLeave)} padding={'15px 8px 15px 8px'} font={"18px"} margin={"25px"} style={{marginTop:'0px'}}>
                {t("Join The Challenge")}
              </EventsButton>}
            {challenge.challenge_status !== 'joined' && teamChallenge && teamChallenge.team_challenge_details && teamChallenge.team_challenge_details.team_details ? (<InformationCard>
              <div className='teamChallenge' style={{display:'flex',alignItems:'center',justifyContent:'space-between', width:'100%'}}>
                <div  style={{display:'flex',alignItems:'center', width:'100%'}}>
                  <div className="image">
                    <img onError={this.fallBack} src={`${ImageUrl +"/"+ teamChallenge.team_challenge_details.team_details?.logo}`} alt="back-arrow" />
                  </div>
                  <div className="field">
                    {t("Team Name")}
                  </div>
                </div>
                <div style={{marginRight:'15px', width:'100%', display:'flex', justifyContent:'end'}}>
                  <span className='value'>{t(teamChallenge.team_challenge_details.team_details.name)}</span>
                </div>
              </div>
            </InformationCard>):null}
            {challenge.challenge_status !== 'joined' && challenge.challenge_status !== 'over' && !isNull(challenge.daily_goal) && challenge.daily_goal > 0 &&
            <React.Fragment>
              {challenge.challenge_status !== 'joined' && challenge.challenge_status !== 'over' && !isNull(challenge.daily_goal) && challenge.daily_goal > 0 &&
              <InformationCard>
                <div className="image">
                  <img src="/public/images/CompanyDashBoardV2/dailyGoal.png" alt="back-arrow" />
                </div>
                <div className="card-wrapper">
                  <div className="first-card">
                    <div className="field">
                      {t("Team Daily Goal")}
                    </div>
                    <div className="value" style={{color:"#FFBF73"}}>
                      {challenge.event_type === 'Total Distance'?DistanceUnit === "KILOMETER"?commaSeperator(convertMilesToKm(challenge.daily_goal)) :commaSeperator(challenge.daily_goal) :commaSeperator(challenge.daily_goal)}&nbsp;
                    </div>
                  </div>
                  <Border margin="auto 15px auto 0px" size="1" background="#005C874D"/>
                  <div className="last-card">
                    <div className="field">
                      {t("My Total Target Goal")}
                    </div>
                    <div className="value" style={{color:"#FFBF73"}}>
                      {challenge.event_type === 'Total Distance'?DistanceUnit === "KILOMETER"?commaSeperator(convertMilesToKm(challenge.target_goal)) :commaSeperator(challenge.target_goal) :commaSeperator(challenge.target_goal)}&nbsp;
                    </div>
                  </div>
                </div>
              </InformationCard>
              }
            </React.Fragment>
            }
            {/*  */} 
          </ButtonContainer> : null}
        {challenge && (challenge.challenge_status === 'over'||challenge.challenge_status !== 'joined') && <AboutContainer marginTop={'0px 0px 15px 0px'} color={"#0D4270"}>
          <div>{t("About The Challenge")}</div>
          <div >{parse(challenge.body)}</div>
        </AboutContainer>}
        {challenge && challenge.challenge_status === 'over' &&
            <EventsButton backgroundColor={'#F4AAA94D'} color={"#005C87"} padding={'15px 8px 15px 8px'} font={"18px"} cursor={"1"}>
              {t("Challenge Completed")}
            </EventsButton>}
        <Suspense fallback={<Waiting/>}>
          <InviteAmigoPopUp userId={userId} history={this.props.history} showModal={this.state.showInviteAmigoPopup} onHide={this.showHideInviteAmigoPopup} challengeId={challenge.id} plusAmigoButton={1} />
          {this.state.leaveChallengePopUp && <LeaveChallengePopup showModal={this.showLeaveModal} challengeId={challenge.id} onClose={this.hideLeaveModal} history={this.props.history} fname={this.props.fname}/>}
        </Suspense>
      </MyTaskContainerV2>
    )
  }
}
TeamChallengeRightSection.propTypes = {
  getUserOverviewDetails: PropTypes.func.isRequired,
  userChallengeDetails: PropTypes.object,
  challenge: PropTypes.object.isRequired,
  teamChallenge:PropTypes.object.isRequired,
  userId: PropTypes.number.isRequired,
  profileImage: PropTypes.string.isRequired,
  joinOrLeaveChallenge: PropTypes.func,
  syncTime: PropTypes.string,
  getInviteAmigosList: PropTypes.func,
  history: PropTypes.object,
  role: PropTypes.string,
  t: PropTypes.func,
  user: PropTypes.object,
  fname: PropTypes.string,
};
const mapStateToProps = (state) => ({
  userChallengeDetails: state.challenges.userChallengeDetails,
  fname: state.profileData.firstName,
  syncTime: state.challenges.syncTime,
});
const mapDispatchToProps = (dispatch) => ({
  joinOrLeaveChallenge: (data) => dispatch(joinOrLeaveChallenge(data)),
  getInviteAmigosList: (userID, challengeId) => dispatch(getInviteAmigosList(userID, challengeId)),
});
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(TeamChallengeRightSection));